import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../AppContext';
import { FaChevronDown, FaMoneyBill } from 'react-icons/fa';
import { Button, Row, Col, Container, FormSelect } from 'react-bootstrap';
import BuySell from './BuySell';
import ContactInformation from './ContactInformation';
import $ from 'jquery';
import Confirmation from './Confirmation';
import Captcha from './Captcha';
import FormError from './Error';
import AppProvider from '../../providers/AppProvider';
import { LeadFormSubmission } from '../../models/LeadFormSubmission';
import { VIPOption } from '../../models/VIPOption';
import ReCaptcha from 'react-google-recaptcha';
import CONSTANTS from '../../models/client/CONSTANTS';
import parse from 'html-react-parser';
import FinancingFreightNeither from './FinancingFreightNeither';

interface Props {

}

enum FORM_STEPS {
    "BUY_SELL",
    "FINANCING_FREIGHT",
    "CONTACT_INFO",
    "CAPTCHA",
    "CONFIRMATION",
    "ERROR"
}

const Home = (props: Props) => {
    const context = useContext(AppContext);
    const videoSectionRef = useRef<HTMLDivElement>(null);

    const [currentStep, setCurrentStep] = useState(FORM_STEPS.BUY_SELL);

    const [isForSelling, setIsForSelling] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [market, setMarket] = useState('');
    const [errorFields, setErrorFields] = useState<{ [field: string]: boolean }>({});

    const [submitting, setSubmitting] = useState(false);

    const [vipLinks, setVIPLinks] = useState<VIPOption[]>([]);

    useEffect(() => {
        let submission = context.cookies.get(CONSTANTS.FORM_SUBMISSION_COOKIE_KEY) as LeadFormSubmission;
        let market = undefined;
        if (submission) {
            market = submission.market;
            // They've already submitted this form before
            setCurrentStep(FORM_STEPS.CONFIRMATION);
        }
        
        getVIPApplicationLinks(market);
    }, []);

    useEffect(() => {
        if (currentStep === FORM_STEPS.FINANCING_FREIGHT) {
            $('#buy-sell').fadeOut(() => {
                $('#financing-freight').fadeIn();
            });
        } else if (currentStep === FORM_STEPS.CONTACT_INFO) {
            $('#buy-sell').fadeOut(() => {
                $('#financing-freight').fadeOut(() => {
                    $('#contact-information').fadeIn();
                });
            });
        } else if (currentStep === FORM_STEPS.CAPTCHA) {
            $('#contact-information').fadeOut(() => {
                $('#captcha').fadeIn();
            });
        } else if (currentStep === FORM_STEPS.CONFIRMATION) {
            // If already hidden these finish immediately
            $('#buy-sell').fadeOut(() => {
                $('#contact-information').fadeOut(() => {
                    $('#captcha').fadeOut(() => {
                        $('#confirmation').fadeIn();
                    });
                });
            });
        } else if (currentStep == FORM_STEPS.ERROR) {
            $('#buy-sell').fadeOut(() => {
                $('#contact-information').fadeOut(() => {
                    $('#captcha').fadeOut(() => {
                        $('#error').fadeIn();
                    });
                });
            });
        }
    }, [currentStep]);

    const getVIPApplicationLinks = (market?: string) => {
        AppProvider.GetVIPApplicationURLs(context.overriddenTLD, market || null).then((result) => {
            if (result) {
                setVIPLinks(result.options);
            }
        });
    }

    const handleBuySellComplete = (isSelling: boolean) => {
        setIsForSelling(isSelling);
        if (isSelling) {
            setCurrentStep(FORM_STEPS.CONTACT_INFO);
        } else {
            setCurrentStep(FORM_STEPS.FINANCING_FREIGHT);
        }
    }

    const submitLeadForm = (name: string, email: string, phoneNumber: string, market: string) => {
        setSubmitting(true);

        let input: LeadFormSubmission = {
            latitude: context.locationLocale?.latitude || null,
            longitude: context.locationLocale?.longitude || null,
            country: context.locationLocale?.country || '',
            email,
            isForSelling,
            needsFinancing: false,
            name,
            market,
            phoneNumber
        }

        return AppProvider.SubmitLeadForm(input).then((result) => {
            if (result) {
                // This will technically expire after 400 days, but I think that's fine
                context.cookies.set(CONSTANTS.FORM_SUBMISSION_COOKIE_KEY, input, { path: '/', expires: new Date('9999-12-1') });
                setCurrentStep(FORM_STEPS.CONFIRMATION);
            }
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const handleContactInfoComplete = (name: string, email: string, phoneNumber: string, market: string) => {

        setName(name);
        setEmail(email);
        setPhoneNumber(phoneNumber);
        setMarket(market);

        let valid = true;
        let errorFields: { [field: string]: boolean } = {};
        if (!name || name.length < 3) {
            errorFields['name'] = true;
            valid = false;
        }
        if (!email || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            errorFields['email'] = true;
            valid = false;
        }

        setErrorFields(errorFields);

        if (valid) {
            getVIPApplicationLinks(market);
            setCurrentStep(FORM_STEPS.CAPTCHA);
        }
    }

    // Because of the captcha resetting, this will fire erroneously with false
    // Only pay attention to this if on the captcha step
    const handleCaptchaComplete = (success: boolean) => {
        if (currentStep === FORM_STEPS.CAPTCHA) {
            if (success) {
                submitLeadForm(name, email, phoneNumber, market);
            } else if (!submitting) {
                setCurrentStep(FORM_STEPS.ERROR);
            }
        }
    }

    const handleSkipDownClick = () => {
        if (videoSectionRef && videoSectionRef.current) {
            videoSectionRef.current.scrollIntoView();
        }
    }

    return (
        <div className='pt-5 text-center'>
            <div className='padding-container'>
                <h1 className='text-display text-cond text-upper fw-bold mb-4'>{context.translations.get('sandhills.freeequipmentvaluationsandmore')}</h1>
                <p className='fs-5'>{context.translations.get('sandhills.simplyloginorfillformtostart')}</p>
                <div style={{ minHeight: 200 }} className='d-flex align-items-center bg-dark my-4 border border-primary rounded'>
                    <BuySell id='buy-sell' style={{ display: 'flex' }} className='flex-column flex-grow-1' onComplete={handleBuySellComplete} />
                    <FinancingFreightNeither style={{ display: 'none' }} id='financing-freight' errors={errorFields} className='flex-column flex-grow-1 m-3' onComplete={() => setCurrentStep(FORM_STEPS.CONTACT_INFO)} />
                    <ContactInformation style={{ display: 'none' }} id='contact-information' errors={errorFields} className='flex-column flex-grow-1 m-3' onComplete={handleContactInfoComplete} />
                    <Captcha style={{ display: 'none' }} id='captcha' className='flex-column flex-grow-1 m-3 text-center' loading={submitting} onComplete={handleCaptchaComplete} />
                    <Confirmation style={{ display: 'none' }} id='confirmation' className='flex-column flex-grow-1 m-3' vipLinks={vipLinks} loading={submitting} />
                    <FormError style={{ display: 'none' }} id='error' className='flex-column flex-grow-1 m-3' />
                </div>
                <Button onClick={handleSkipDownClick} variant='gray' style={{ height: 50, width: 50 }} className='mt-3 mb-5 d-inline-flex rounded-circle align-items-center justify-content-center'>
                    <FaChevronDown size={40} />
                </Button>
                <div className='mt-5 about'>
                    <p className='fs-1 text-primary text-upper fw-bold text-cond'>{context.translations.get('sandhills.whatisvipcamelcase')}</p>
                    <Row className='text-start'>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <img src='/images/instant-values.png' />
                                </div>
                                {parse(context.translations.get('sandhills.instantvalueheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.vipdescription')}
                            </p>
                        </Col>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <img src='/images/predictive-tool.png' />
                                </div>
                                {parse(context.translations.get('sandhills.industrymostpowerfulheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.vippoweredby')}
                            </p>
                        </Col>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <img src='/images/free-values.png' />
                                </div>
                                {parse(context.translations.get('sandhills.precisevaluationsforfreeheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.fivefreeevaluations')}
                            </p>
                        </Col>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <img src='/images/future-planning.png' />
                                </div>
                                {parse(context.translations.get('sandhills.planforthefutureheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.usefuturecasttogetvaluepredictions')}
                            </p>
                        </Col>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <FaMoneyBill size={40} className='text-light-gray' />
                                </div>
                                {parse(context.translations.get('sandhills.improveyourroiheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.monitoraskingvaluesandauctionresults')}
                            </p>
                        </Col>
                        <Col xs='12' md='6' lg='4' className='mt-2'>
                            <div className='fs-5 text-cond mb-3 fw-bold'>
                                <div className='bg-gray p-2 d-inline-block rounded me-2'>
                                    <img src='/images/sandhills-cloud.png' />
                                </div>
                                {parse(context.translations.get('sandhills.sandhillscloudheader'))}
                            </div>
                            <p>
                                {context.translations.get('sandhills.saveyourassetsforlater')}
                            </p>
                        </Col>
                    </Row>
                </div>
                <div ref={videoSectionRef} style={{ paddingTop: 50 }}>
                    <img style={{ display: 'inline-block' }} height={60} src='https://media.sandhills.com/cdn/images/vip/icon.svg' />
                    <Row className='mt-3'>
                        <Col xs='12' md='6' className='text-end'>
                            <video style={{ display: 'inline-block', width: '100%', height: 'auto', maxWidth: 720, cursor: 'pointer' }} poster='/images/poster.jpg' controls>
                                <source src='videos/VIP.mp4' type='video/mp4' />
                            </video>
                        </Col>
                        <Col xs='12' md='6'>
                            <p style={{ maxWidth: 720 }} className='text-start text-cond fs-5'>
                                {parse(context.translations.get('sandhills.vipacceleratesyourlookups'))}&nbsp;
                                {context.translations.get('sandhills.justaclickortapaway')}&nbsp;
                                {context.translations.get('sandhills.fillinbasicinfoandgetinstantvalues')}
                                <br />
                                <br />
                                {context.translations.get('sandhills.vipisajumpingoffpoint')}&nbsp;
                                {context.translations.get('sandhills.morefunctionsontheway')}
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div style={{ maxHeight: 200, overflow: 'hidden', margin: '100px 0' }} className='d-flex align-items-center jusitfy-content-center'>
                <img width='100%' src='/images/bg-3.jpg' />
            </div>
            <div className='padding-container'>
                <Row className='py-4 align-items-center'>
                    <Col xs='12' md='4' className='align-items-center justify-content-center'>
                        <img style={{ maxWidth: '100%' }} src='/images/no-waiting.png' />
                    </Col>
                    <Col style={{ flexBasis: '60%' }} className='text-start'>
                        <p className='text-primary text-upper fs-2 fw-bold'>{context.translations.get('sandhills.nowaiting')}</p>
                        <p>
                            {context.translations.get('sandhills.openasupportedtradesite')}&nbsp;
                            {context.translations.get('sandhills.taporclickfloatingvipbutton')}&nbsp;
                            {context.translations.get('sandhills.accessportaldirectly')}
                        </p>
                    </Col>
                </Row>
                <Row className='flex-row-reverse py-4 align-items-center'>
                    <Col xs='12' md='4' className='align-items-center justify-content-center'>
                        <img style={{ maxWidth: '100%' }} src='/images/fill-blanks.png' />
                    </Col>
                    <Col style={{ flexBasis: '60%' }} className='text-start'>
                        <p className='text-primary text-upper fs-2 fw-bold'>{context.translations.get('sandhills.fillblanks')}</p>
                        <p>
                            {context.translations.get('sandhills.fillouttabs')}&nbsp;
                            {context.translations.get('sandhills.autofill')}&nbsp;
                            {context.translations.get('sandhills.clicknext')}
                            <br />
                            <br />
                            {context.translations.get('sandhills.enterasmuchinfoasyoucan')}
                        </p>
                    </Col>
                </Row>
                <Row className='py-4 align-items-center'>
                    <Col xs='12' md='4' className='align-items-center justify-content-center'>
                        <img style={{ maxWidth: '100%' }} src='/images/precise-values-new.png' />
                    </Col>
                    <Col style={{ flexBasis: '60%' }} className='text-start'>
                        <p className='text-primary text-upper fs-2 fw-bold'>{context.translations.get('sandhills.equipmentworth')}</p>
                        <p>
                            {context.translations.get('sandhills.fleetevaluatorreturn')}&nbsp;
                            {context.translations.get('sandhills.fastportal')}&nbsp;
                            {context.translations.get('sandhills.valuationsaresavedautomatically')}&nbsp;
                            {context.translations.get('sandhills.simplyupdateaspec')}
                            <br />
                            <br />
                            {context.translations.get('sandhills.registeredusergetuptofivefreeevaluations')}
                        </p>
                    </Col>
                </Row>
                <Row className='flex-row-reverse py-4 align-items-center'>
                    <Col xs='12' md='4' className='align-items-center justify-content-center'>
                        <img style={{ maxWidth: '100%' }} src='/images/save-assets.png' />
                    </Col>
                    <Col style={{ flexBasis: '60%' }} className='text-start'>
                        <p className='text-primary text-upper fs-2 fw-bold'>{context.translations.get('sandhills.justsavedassets')}</p>
                        <p>
                            {context.translations.get('sandhills.assetlistincludessearchfunction')}&nbsp;
                            {context.translations.get('sandhills.clicksellbuttontostartsalesprocess')}&nbsp;
                            {context.translations.get('sandhills.vipwillnotifyyourrep')}
                        </p>
                    </Col>
                </Row>
                <Row className='py-4 align-items-center'>
                    <Col xs='12' md='4' className='align-items-center justify-content-center'>
                        <img style={{ maxWidth: '100%' }} src='/images/futurecast.png' />
                    </Col>
                    <Col style={{ flexBasis: '60%' }} className='text-start'>
                        <p className='text-primary text-upper fs-2 fw-bold'>{context.translations.get('sandhills.futurecast')}</p>
                        <p>
                            {context.translations.get('sandhills.unlockfuturecastdesc')}&nbsp;
                            {context.translations.get('sandhills.futurecastinformedby')}&nbsp;
                            {context.translations.get('sandhills.futurecastdisplaysauctionandmarket')}&nbsp;
                            {context.translations.get('sandhills.clickortappointtoseevalueforthatmonth')}
                        </p>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Home;