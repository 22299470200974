import React, { CSSProperties } from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FaMoneyBill } from 'react-icons/fa';
import { AppContext } from '../../AppContext';

interface Props {
    id?: string,
    className?: string,
    style?: CSSProperties,
    onComplete: (isSelling: boolean) => void,
}

const BuySell = (props: Props) => {

    const context = useContext(AppContext);

    return (
        <div id={props.id} className={props.className} style={props.style}>
            <p className='fs-5'>{context.translations.get('sandhills.willthesevaluationsbeusedtohelpbuyorsell')}</p>
            <div className='d-grid gap-3 m-3'>
                <Button onClick={() => props.onComplete(false)} size='lg' variant='gray'><FaMoneyBill size={30} className='me-2 text-light-gray' /> {context.translations.get('sandhills.buying')}</Button>
                <Button onClick={() => props.onComplete(true)} size='lg' variant='gray'><img src='/images/free-values.png' /> {context.translations.get('sandhills.selling')}</Button>
            </div>
        </div>
    );
}

export default BuySell;