import React, { useContext, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import Select, { components } from 'react-select';
import { AppContext } from '../AppContext';
import { LocaleSelectOption } from '../models/LocaleSelectOption';
import { SelectOption } from '../models/SelectOption';
import AppProvider from '../providers/AppProvider';

interface Props {
    className?: string,
    onChange?: (value: string) => void
}

const LocaleDropdownOption = (props: any) => {
    const { Option } = components;

    return (
        <Option {...props}>
            <ReactCountryFlag className='me-1' countryCode={props.data.countryCode} svg />
            {props.data.label}
        </Option>
    );
}

const LocaleDropdownValue = (props: any) => {
    const { SingleValue } = components;
    
    return (
        <SingleValue {...props}>
            <ReactCountryFlag className='me-1' countryCode={props.data.countryCode} svg />
            {/* <span className='d-none d-xl-inline-block'>{props.data.label}</span> */}
        </SingleValue>
    );
}

const LocaleDropdown = (props: Props) => {
    const context = useContext(AppContext);

    const [locales, setLocales] = useState<LocaleSelectOption[]>([]);
    const [selectedLocale, setSelectedLocale] = useState<LocaleSelectOption | null>(null);

    useEffect(() => {
        AppProvider.GetSupportedLocales().then((result) => {
            if (result) {
                setLocales(result.options);
                // The context is the truth for selected locale
                if (context.cultureCode)
                    setSelectedLocale(result.options.find(x => x.value.toLowerCase() === context.cultureCode.toLowerCase()) || null);
            }
        })
    }, []);

    useEffect(() => {
        setSelectedLocale(locales.find(x => x.value === context.cultureCode) || null);
    }, [context.cultureCode]);

    const handleChange = (value: LocaleSelectOption | null) => {
        setSelectedLocale(value);
        if (props.onChange)
            props.onChange(value?.value);
    }

    return (
        <div className={props.className + ' text-light-gray'}>
            <Select value={selectedLocale} onChange={handleChange} isSearchable={false} className='locale-dropdown' classNamePrefix={'locale-dropdown'} options={locales} components={{ Option: LocaleDropdownOption, SingleValue: LocaleDropdownValue }} />
        </div>
    );
}

export default LocaleDropdown;