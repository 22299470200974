import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Spinner } from 'react-bootstrap';
import { matchPath, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppContext } from './AppContext';
import Layout from './layout/Layout';
import Cookies from 'universal-cookie';
import { AppInitialization } from './models/AppInitialization';
import CookiePolicy from './pages/cookiepolicy/CookiePolicy';
import Home from './pages/home/Home';
import NotFound from './pages/notfound/NotFound';
import PrivacyPolicy from './pages/privacypolicy/PrivacyPolicy';
import AppProvider from './providers/AppProvider';
import TranslationProvider from './providers/client/TranslationProvider';
import CONSTANTS from './models/client/CONSTANTS';
import ServerInfo from './pages/serverinfo/ServerInfo';
import { GenericModal } from 'sandhills-react-bootstrap-ui/dist/esm';
import ROUTES from './Routes';
import { LocationLocale } from './models/LocationLocale';
import ScrollTop from './layout/ScrollTop';

const cookies = new Cookies();

const App = () => {
  const location = useLocation();

  const [app, setApp] = useState<AppInitialization | null>(null);
  const [isCultureRoute, setIsCultureRoute] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [locationLocale, setLocationLocale] = useState<LocationLocale | null>(null);
  const [error, setError] = useState('');

  const translationProvider = useRef<TranslationProvider>(new TranslationProvider({}, false));
    
  useEffect(() => {
    // Manually get URL param since we are outside of the current route
    // useParams only works inside the root
    const match = matchPath({ path: '/:culture' }, location.pathname);
    let cultureCode = match?.params?.culture;

    Object.values(ROUTES).forEach((route) => {
      if (match?.pathname === route) {
        cultureCode = undefined;
      }
    });

    // It's fine this won't be available for the initial load
    // Not used on initial load anyway
    if (cultureCode)
      setIsCultureRoute(true);

    initializeApp(cultureCode).then((app) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          handleLocationReceived(position, app?.cultureCode);
        }, (err) => {
          handleLocationReceived(null, app?.cultureCode);
        });
      }
    });
  }, []);

  const initializeApp = (cultureCode?: string) => {
    if (cultureCode) {
      cookies.set(CONSTANTS.CULTURE_COOKIE_KEY, cultureCode, { path: '/' });

      if (isCultureRoute && app?.cultureCode !== cultureCode) {
        window.history.replaceState(null, document.title, `${window.location.protocol}//${window.location.host}/${cultureCode}`);
      }
    }

    return AppProvider.InitializeApp().then((result) => {
      if (result) {
        translationProvider.current = new TranslationProvider(result.rawTranslationData, false);
        setApp(result); // This will be enough to trigger a whole app refresh with the new values
      } else {
        setError('There was an error loading the site. Please try again later.');
      }

      setLangAttribute(result?.cultureCode);

      return result;
    });
  }

  const setLangAttribute = (cultureCode?: string) => {
    const htmlTag = document.querySelector('html');
    if(htmlTag){
      const langForTag = cultureCode!.substring(0,2);
      htmlTag.lang = langForTag;
    }
  }

  const handleLocationReceived = (position: GeolocationPosition | null, currentCulture: string | undefined) => {
    // Figure out what the ideal culture, TLD, and/or route is for this location
    AppProvider.GetCultureFromLocation(position?.coords?.latitude, position?.coords?.longitude).then((result) => {
      if (result) {
        setLocationLocale(result);
      }
      if (result && result.cultureCode !== currentCulture) {
        let hasShownSuggested = cookies.get(CONSTANTS.SUGGESTED_POPUP_COOKIE_KEY);
        if (!hasShownSuggested)
          setShowRedirectModal(true);
      }
    });
  }

  const handleSwitchToSuggestedLocale = () => {
    setShowRedirectModal(false);
    initializeApp(locationLocale?.cultureCode);
  }

  const handleRefuseSuggestedLocale = () => {
    cookies.set(CONSTANTS.SUGGESTED_POPUP_COOKIE_KEY, true, { path: '/' });
    setShowRedirectModal(false);
  }

  const renderRedirectModalBody = () => {
    return (
      <>
        <p>
          This site is available in {locationLocale?.languageName}. Would you like to switch?
        </p>
      </>
    );
  }

  const renderRedirectModalButtons = () => {
    return (
      <>
        <Button variant='gray' onClick={handleRefuseSuggestedLocale}>No</Button>
        <Button onClick={handleSwitchToSuggestedLocale}>Yes</Button>
      </>
    );
  }

  if (error) {
    return (
      <div style={{ marginTop: 250 }} className='d-flex mx-3 fw-bold align-items-center justify-content-center'>
        <div style={{ width: 500, maxWidth: '100%' }} className='p-3 border border-danger text-danger text-center bg-dark'>
          <span>{error}</span>
        </div>
      </div>
    );
  }

  if (!app) {
    return (
      <div style={{ marginTop: 200 }} className='d-flex align-items-center justify-content-center'>
        <Spinner className='text-primary' as='span' animation='border' />
      </div>
    );
  }

  return (
    <AppContext.Provider value={{
      ...app,
      cookies,
      initializeApp,
      translations: translationProvider.current,
      locationLocale
    }}>
      <Helmet>
        <title>{translationProvider.current.get('sandhills.viplandingtitle')}</title>
        <meta name='description' content={translationProvider.current.get('sandhills.viplandingmeta')}/>
      </Helmet>
      <ToastContainer position='top-center' autoClose={false} />
      <GenericModal show={showRedirectModal} title='Change Locale?' renderContent={renderRedirectModalBody} renderButtons={renderRedirectModalButtons} onHide={() => setShowRedirectModal(false)} />
      <ScrollTop />
      <Layout>
        <Routes>
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />

          <Route path={ROUTES.HOME} element={<Home />} />

          <Route path={ROUTES.CULTURE} element={<Home />} />

          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />

          <Route path={ROUTES.COOKIE_POLICY} element={<CookiePolicy />} />

          <Route path={ROUTES.SERVER_INFO} element={<ServerInfo />} />
        </Routes>
      </Layout>
    </AppContext.Provider>
  );
};

export default App;